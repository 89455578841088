@import url("https://fonts.googleapis.com/css?family=Lexend Deca");
@import "../node_modules/win-animate/src/animateCore.scss";

// used by modals
@import "../node_modules/win-animate/src/fading_entrances/fadeIn.scss";
@import "../node_modules/win-animate/src/sliding_entrances/slideInLeft.scss";

@import "../node_modules/win-animate/src/attention_seekers/pulse.scss";

:root {
  // white scales
  --main-white-1: #fff;
  --main-white-2: #fafafa;
  --main-white-3: #efefef;
  --main-white-4: #eee;

  // grey scales
  --main-lighter-grey: #ddd;
  --main-light-grey: #ccc;
  --main-grey: #aaa;
  --main-dark-grey: #888;
  --main-darker-grey: #444;

  // black scales
  --main-black-translucid: #05050599;

  // purple scales
  --main-purple-lighter: #d7bfd8;
  --main-purple-light: #a85baa;
  --main-purple: #a800ae;
  --main-purple-dark: #630066;
  --main-purple-darker: #200721;
  --main-purple-alpha: #18001ea1;

  // orange scales
  --main-lighter-orange: #ffccba;
  --main-light-orange: #f3c1b0;
  --main-orange: #fe4400;
  --main-dark-orange: #b83403;
  --main-darker-orange: #38170b;
  --main-app-orange: #db4d48;

  // status
  --main-online: #85d614;
  --main-away: #ffa31a;
  --main-offline: #ccc;
}

// =========================
// =========================
$COLOR_body_bg: var(--main-white-2);
$inner_width: 1060px;
// =========================
// =========================

//#region MEDIA_ Break Points Media Queries

// @media #{$pcInterlude} {

// 799 because 800 is already 'pc'
$MEDIA_largeMobile: "(min-width:500px) and (max-width:799px)";
$MEDIA_smallMobile: "(max-width:320px)";
// 799 because 800 is already 'pc'
$MEDIA_mobile: "(max-width:799px)";
$MEDIA_pc: "(min-width:800px)";
$MEDIA_pcInterlude: "(min-width:800px) and (max-width:950px)";
$MEDIA_pcBreak: "(max-width:1230px)";
$MEDIA_largePc: "(min-width:1500px)";
//#endregion

//#region ZINDEX_ Overlaps
$ZINDEX_below: 10;
$ZINDEX_middle: 20;
$ZINDEX_above: 30;
$ZINDEX_popups: 100;
//#endregion

//#region SPACE_ Spaces, Margins and Paddings
$SPACE_pc_margin_top: 60px;
$SPACE_spacing_global: 10px;
$SPACE_radius_global: 4px;
//#endregion

//#region FONT_ fonts
$FONT_normal_text: 13px;
$FONT_small_text: 10px;
$FONT_big_text: 15px;
$FONT_bigger_text: 18px;
$FONT_h2: 23px;
$FONT_h1: 35px;

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background: #111;
  @media #{$MEDIA_pc} {
    background: linear-gradient(90deg, #ecb67c, #f8855c, #a350f0);
    background-size: 1000% 1000%;
    -webkit-animation: a3 25s ease infinite;
    -moz-animation: a3 25s ease infinite;
    -o-animation: a3 25s ease infinite;
    animation: a3 25s ease infinite;
  }
  text-align: center;
  font-family: "Lexend Deca", sans-serif;
  overflow-x: hidden;
  margin: 0px;
}
@-webkit-keyframes a3 {
  0% {
    background-position: 0% 56%;
  }

  50% {
    background-position: 100% 45%;
  }

  100% {
    background-position: 0% 56%;
  }
}

@-moz-keyframes a3 {
  0% {
    background-position: 0% 56%;
  }

  50% {
    background-position: 100% 45%;
  }

  100% {
    background-position: 0% 56%;
  }
}

@-o-keyframes a3 {
  0% {
    background-position: 0% 56%;
  }

  50% {
    background-position: 100% 45%;
  }

  100% {
    background-position: 0% 56%;
  }
}

@keyframes a3 {
  0% {
    background-position: 0% 56%;
  }

  50% {
    background-position: 100% 45%;
  }

  100% {
    background-position: 0% 56%;
  }
}

html {
  margin: 0px;
  padding: 0px;
}

#app {
  width: 100vw;
  // height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.country {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #cccc;

  .countryContainer {
    display: inline-block;
    width: 100vw;
    max-width: 500px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 16px #9d9a9a;

    select {
      font-size: 22px;
      font-weight: bold;
      color: #555;
      padding: 10px;
    }
    button {
      // display: block;
      margin-top: 20px;
      padding: 10px 30px;
      font-size: 20px;
      color: #333;
      cursor: pointer;
      // font-weight: bold;
      font-family: "Lexend Deca", sans-serif;

      background-color: #00ffa7;
      border: 0px;

      &:hover {
        background-color: #02ce86;
      }
    }
  }
}

.mainDiv {
  width: 100vw;
  height: 100vh;
  min-height: 600px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  .box {
    width: 90vw;
    max-width: 1060px;
    margin-top: -100px;
    height: 550px;
    background-color: #fff;
    display: inline-block;
    position: relative;
    box-shadow: 0 1px 20px 0 #523ef5;
    z-index: 1;
    text-align: left;

    .cover {
      position: absolute;
      top: -50px;
      left: 0px;
      width: 90vw;
      max-width: 1060px;
      margin-top: 50px;
      background-color: #fff;
      height: 550px;
      z-index: 0;
    }

    .logo {
      width: 200px;
      position: absolute;
      top: 30px;
      left: 30px;
    }

    .title {
      font-size: 40px;
      color: #333;
      position: absolute;
      left: 5%;
      top: 20%;
      font-weight: bold;
    }
    .subtitle {
      font-size: clamp(20px, 2.07vw, 25px);
      color: rgb(90, 90, 90);
      position: absolute;
      left: 5%;
      top: 30%;
      font-weight: bold;
      max-width: 650px;
    }

    .buttons {
      position: absolute;
      left: 5%;
      top: 45%;

      button {
        font-size: 20px;
        color: #fff;
        cursor: pointer;
        // font-weight: bold;
        font-family: "Lexend Deca", sans-serif;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        width: 450px;

        border-radius: 2px;

        .left {
          margin-left: -5px;
          margin-right: 10px;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            fill: #fff;
            height: 40px;
            width: 40px;
            margin-top: -5px;
          }
        }

        background-color: #00ffa7;
        border: 0px;

        &:hover {
          background-color: #02ce86;
        }
      }

      .twitter {
        background-color: #00acee;
        &:hover {
          background-color: #0393cc;
        }

        .left {
          background-color: #0393cc;
        }
      }

      .facebook {
        background-color: #3b5998;
        &:hover {
          background-color: #283e6e;
        }
        .left {
          background-color: #283e6e;
        }
      }

      .register {
        background-color: #e74f4f;
        &:hover {
          background-color: #c93939;
        }
        .left {
          background-color: #c93939;
        }
      }

      .login {
        background-color: #6bb166;
        &:hover {
          background-color: #469640;
        }
        .left {
          background-color: #469640;
        }
      }
    }

    .links {
      position: absolute;
      left: 5%;
      bottom: 5%;

      a {
        margin-right: 15px;
        color: #333;
        text-decoration: none;
        font-size: 13px;
        &:hover {
          color: #999;
        }
      }
    }
  }

  .playstore {
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
    width: 90vw;
    max-width: 1060px;

    text-align: left;

    .qrcode {
      width: 100px;
      height: 100px;
    }

    .googleImg {
      height: 80px;
    }
  }
}

.arrow_down_pc {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  margin-top: -10vh;
  margin-bottom: 10vh;

  cursor: pointer;

  ._img {
    transform: rotate(90deg);
  }

  .arrow {
    width: 50px;
  }
}

.divider1 {
  position: relative;
  width: 100vw;
  height: 100px;
  svg {
    padding-top: 0px;
  }

  .custom-shape-divider-bottom-1690554366 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1690554366 svg {
    position: relative;
    display: block;
    width: calc(142% + 1.3px);
    height: 63px;
    transform: rotateY(180deg);
  }

  .custom-shape-divider-bottom-1690554366 .shape-fill {
    fill: #111;
  }
}

.divider2 {
  position: relative;
  width: 100vw;
  height: 100px;
  svg {
    padding-top: 0px;
  }
  .custom-shape-divider-top-1690577011 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-top-1690577011 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 36px;
  }

  .custom-shape-divider-top-1690577011 .shape-fill {
    fill: #111111;
  }
}

.divider3 {
  position: relative;
  width: 100vw;
  height: 100px;
  svg {
    padding-top: 0px;
  }
  .custom-shape-divider-bottom-1690721438 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1690721438 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 86px;
  }

  .custom-shape-divider-bottom-1690721438 .shape-fill {
    fill: #111111;
  }
}

.splash1_pc {
  width: 100%;
  background-color: #4b4b4b;
  // background-image: url("/images/two-girls-full.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0px;
  text-align: left;
  margin-top: 50px;
  backdrop-filter: blur(10px);

  ._inner {
    position: relative;
    width: $inner_width;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    font-size: 80px;
    color: #fff;
    z-index: 10;
    line-height: 70px;
    margin-bottom: 0px;
    font-family: "Tilt Warp";
    padding: 40px 0px;
    padding-top: 20px;
    text-align: right;
  }

  .girls {
    // margin-left: -45px;
    // border-top-right-radius: 150px;
  }
}

.splash1 {
  margin-top: 30px;
  width: 100%;
  position: relative;
  text-align: left;
  padding: 0px 20px;

  .btn {
    background-color: transparent;
    padding: 20px 40px;
    border-radius: 50px;
    border: 1px solid #fff;
    color: #fff;
    font-weight: 800;
    font-size: clamp(15px, 2vw, 20px);
    margin-top: 40px;
  }
  p {
    font-size: 80px;
    color: #fff;
    z-index: 10;
    line-height: 70px;
    margin-bottom: 0px;
    font-family: "Tilt Warp";
    font-weight: 400;
    letter-spacing: -2px;
  }

  .btn-facebook {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 10px 40px;
    margin-top: 50px;
  }

  .btn-facebook svg {
    width: 45px;
    height: 45px;
    fill: #ccc;
    padding-top: 0px;
    margin-right: 10px;
  }

  .btn-twitter {
    margin-top: 20px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    align-content: center;
    padding: 10px 40px;
  }

  .btn-twitter svg {
    width: 45px;
    height: 45px;
    fill: #ccc;
    padding-top: 0px;
    margin-right: 10px;
  }

  .jet {
    position: absolute;
    right: -80px;
    top: -30px;
    width: 250px;
    z-index: -1;
  }
}

.div_cards {
  background-color: #111;
  width: 100vw;
  display: flex;
  justify-content: center;
  .div_cards_inner {
    width: $inner_width;
    padding: 40px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .card {
      width: 300px;
      padding: 40px;
      margin-bottom: 20px;
      border-radius: 20px;
      border: 1px solid #333;
      text-align: left;
    }
    .card .icon {
      padding: 15px;
      border-radius: 10px;
      background-color: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-bottom: 20px;
      width: fit-content;
    }
    .card .icon svg {
      width: 30px;
      fill: #fff;
    }

    .card .title_ {
      color: #fff;
      font-size: 30px;
      margin-bottom: 20px;
      font-family: "Tilt Warp";
      font-weight: 400;
      letter-spacing: -2px;
    }

    .card .text {
      color: #ccc;
      font-size: 15px;
    }
  }
}

.testimonials {
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 50px;

  @media (max-width: 799px) {
    margin-top: 90px;
  }
  .inner {
    width: $inner_width;
    padding: 0px 0px;

    @media (max-width: 799px) {
      width: auto;
      padding: 0px 10px;
    }

    ._title {
      color: #eee;
      font-size: 40px;
      margin-bottom: 60px;
      display: block;
      font-family: "Tilt Warp";
      font-weight: normal;
      letter-spacing: -2px;
    }

    .review {
      display: flex;
      flex-direction: column;
      margin-bottom: 80px;

      ._stars {
        svg {
          fill: transparent;
          width: 30px;
          height: 30px;
          @media (max-width: 799px) {
            #🔹-Icon-Color {
              fill: #dbff00;
            }
          }
        }
      }

      ._text {
        color: #fff;
        font-size: 60px;
        line-height: 55px;
        margin-bottom: 20px;
        margin-top: 20px;
        display: block;
        font-family: "Tilt Warp";
        font-weight: 400;
        letter-spacing: -1px;

        @media (max-width: 799px) {
          font-size: 30px;
          line-height: 40px;
        }
      }

      ._user {
        @media (max-width: 799px) {
          color: #aaa;
        }
      }
    }
  }
}

.resourcesDiv {
  width: 100vw;
  text-align: center;

  margin-top: 200px;
  @media #{$MEDIA_mobile} {
    margin-top: 0px;
  }

  background-color: #111;
  color: #eee;

  .box {
    width: 90vw;
    max-width: 1060px;
    margin-top: 50px;
    display: inline-block;
    position: relative;
    text-align: left;

    .title {
      font-size: 40px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .subtitle {
      font-size: 20px;
      color: #ffb670;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .column {
        max-width: 238px;

        @media #{$MEDIA_mobile} {
          max-width: none;
          img {
            width: 100%;
            margin-top: 40px;
          }
        }

        .flex_title {
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          margin-top: 10px;
        }

        .flex_text {
          font-size: 14px;
          font-weight: bold;
          margin-top: 10px;
          color: #ddd;
        }
      }
    }
  }
}

.footer {
  width: 100vw;
  text-align: center;
  background-color: #111;
  color: #eee;
  height: 450px;

  .box {
    width: 90vw;
    max-width: 1060px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    img {
      margin-top: 300px;
    }
    span {
      margin-top: 5px;
      font-size: 12px;
      font-weight: normal;
    }
    ._links {
      a {
        text-decoration: none;
        color: #eee;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}

.phone {
  width: 270px;
  position: absolute;
  top: 44px;
  right: 59px;
  z-index: 10;
}

.screens_pc {
  margin-top: 60px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  perspective: 100px;
  width: 400px;
  position: absolute;
  top: 5px;
  right: 30px;
  z-index: 10;

  @media (max-width: 799px) {
    position: relative;
    width: 100%;
    right: auto;
    top: auto;
    margin-top: -128px;
  }

  .img_screen {
    border-top: 10px solid #ccc;
    border-left: 3px solid #999;
    border-right: 3px solid #ccc;
    border-bottom: 15px solid #888;
    border-radius: 4px;
  }

  .img_screen1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 220px;
    z-index: 1;
    transform: rotateY(-2deg);
  }

  .img_screen2 {
    width: 250px;
    margin-top: -27px;
    z-index: 10;
    box-shadow: 0px 2px 13px 3px #b031b89e;
    @media (max-width: 799px) {
      box-shadow: none;
    }
  }

  .img_screen3 {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 220px;
    z-index: 1;
    transform: rotateY(2deg);
  }
}

.smoke {
  position: absolute;
  width: 350px;
}

.modalBackground {
  background-color: #000a;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  overflow: auto;
  text-align: center;

  .modal {
    display: inline-block;
    width: 500px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 100px;
    text-align: left;
    padding: 30px;

    @media #{$MEDIA_mobile} {
      width: 100vw;
      min-height: 100vh;
      margin-top: 0px;
      margin: 0px;
    }

    .title {
      font-size: 40px;
      font-weight: bold;
      color: #bbb;
      margin-bottom: 30px;
    }

    input {
      padding: 15px 10px;
      font-size: 15px;
      color: rgb(228, 228, 228);
      cursor: pointer;
      // font-weight: bold;
      font-family: "Lexend Deca", sans-serif;

      background-color: #2e2e2e;
      border: 0px;
      display: block;

      width: 100%;
    }

    p {
      margin: 5px;
      margin-top: 20px;
    }

    .check {
      margin-top: 20px;
      display: flex;
      align-items: center;
    }
    .input_check {
      padding: 10px;
      width: auto;
      margin-right: 20px;
    }

    .icons_xmark {
      fill: red !important;
    }

    button {
      margin-top: 30px;
      margin-bottom: 50px;

      padding: 10px 30px;
      font-size: 20px;
      color: #333;
      cursor: pointer;
      // font-weight: bold;
      font-family: "Lexend Deca", sans-serif;

      background-color: #00ffa7;
      border: 0px;

      &:hover {
        background-color: #02ce86;
      }
    }

    .btn_link {
      display: block;
      background-color: transparent;
      padding-left: 0px;
      color: #666;
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 0px;

      &:hover {
        background-color: transparent;
        color: #999;
      }
    }
  }
}

.mainDivMobile {
  // height: 100vh;
  // min-height: 660px;

  width: 100vw;
  display: flex;
  flex-direction: column;
  .top {
    flex: none;
    margin-top: 100px;
    img {
      width: 200px;
    }
    p {
      margin: 0px;
      margin-bottom: 40px;
      font-size: 12px;
      color: #fff;
    }
  }
  .bottom {
    flex: auto;
    background-color: #fff;
    border-radius: 40px 40px 0px 0px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding-bottom: 100px;

    .buttons {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        flex: content;

        font-size: 15px;
        color: #fff;
        cursor: pointer;
        // font-weight: bold;
        font-family: "Lexend Deca", sans-serif;
        height: 40px !important;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        width: 340px;
        max-width: 90vw;

        border-radius: 2px;

        .left {
          margin-left: -5px;
          margin-right: 10px;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            fill: #fff;
            height: 30px;
            width: 30px;
            margin-top: -5px;
          }
        }

        background-color: #00ffa7;
        border: 0px;

        &:hover {
          background-color: #02ce86;
        }
      }

      .googleplay {
        background-color: #9e8e34;
        &:hover {
          background-color: #6d6223;
        }

        .left {
          background-color: #6d6223;
        }
      }

      .twitter {
        background-color: #00acee;
        &:hover {
          background-color: #0393cc;
        }

        .left {
          background-color: #0393cc;
        }
      }

      .facebook {
        background-color: #3b5998;
        &:hover {
          background-color: #283e6e;
        }
        .left {
          background-color: #283e6e;
        }
      }

      .register {
        overflow: hidden;
        border-radius: 20px;
        background-color: #e74f4f;
        &:hover {
          background-color: #c93939;
        }
        .left {
          background-color: #c93939;
        }
      }

      .login {
        border-radius: 20px;
        overflow: hidden;
        background-color: #6bb166;
        &:hover {
          background-color: #469640;
        }
        .left {
          background-color: #469640;
        }
      }
    }

    .links {
      .btn_link {
        display: block;
        background-color: transparent;
        padding-left: 0px;
        color: #666;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 0px;

        border: 0px;
        font-size: 15px;
        margin: 20px;

        &:hover {
          background-color: transparent;
          color: #999;
        }
      }
    }

    .separator {
      display: block;
    }

    .line {
      overflow: hidden;
      text-align: center;
    }

    .line:after,
    .line:before {
      background-color: #ccc;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 35%;
    }

    .line:before {
      right: 0.5em;
      margin-left: -50%;
    }

    .line:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }
}

.mainDivApp {
  height: 100vh;
}

.error {
  color: red;
  font-size: 12px;
}

svg {
  width: 44px;
  fill: #666;
  padding-top: 5px;
}

.lgpd {
  z-index: 1000;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  text-align: center;
  .container {
    display: inline-block;
    width: 100%;
    max-width: 700px;
    background-color: #222;
    color: #ccc;
    font-size: 12px;
    padding: 20px;
    div {
      margin-bottom: 10px;
    }
    a {
      color: #fff;
    }

    button {
      padding: 10px 30px;
      font-size: 20px;
      color: #333;
      cursor: pointer;
      // font-weight: bold;
      font-family: "Lexend Deca", sans-serif;

      background-color: #00ffa7;
      border: 0px;

      &:hover {
        background-color: #02ce86;
      }
    }
  }
}

.passShow {
  display: flex;
  justify-content: space-between;
  .show {
    color: #777;
    cursor: pointer;
    &:hover {
      color: #111;
    }
  }
}
