$COLOR_purple: "#a800ae";
$COLOR_purple_lighter: "#d7bfd8";
$COLOR_backdrop: "#05050599";

.feedLoader {
    // margin-top: $SPACE_spacing_global * 3;
    width: 100%;
    text-align: center;
    svg {
        width: 70px !important;
        fill: $COLOR_purple !important;
    }
}

.div_loaderPull {
    width: 100vw;
    text-align: center;
    position: absolute;
    z-index: 100000;
    top: 0px;
    left: 0px;
    svg {
        width: 40px;
        height: 40px;
    }
}

.svg_base {
    animation: rotator 1s linear infinite;
    transform-origin: center;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.circle {
    stroke-dasharray: 265;
    stroke-dashoffset: 0;
    transform-origin: center;
    stroke: $COLOR_purple;
    fill: $COLOR_purple_lighter;
    animation: dash 3s ease-in-out infinite;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 265;
        opacity: 0.3;
    }
    50% {
        stroke-dashoffset: 65;
        transform: rotate(90deg);
        opacity: 0.6;
    }
    100% {
        stroke-dashoffset: 265;
        transform: rotate(360deg);
        opacity: 0.3;
    }
}

.full_screen_loader {
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 1000;
    background-color: $COLOR_backdrop;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 20px;

    .text {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
        color: "#FFF";
        font-weight: bold;
        width: 100%;
        text-align: center;
    }

    .warn {
        color: "#FFF";
        width: 100%;
        text-align: center;
    }

    svg {
        width: 100px;
        height: 100px;
        fill: "#FFF";
    }
}
